import React, { forwardRef, useEffect } from "react";
interface TextInputProps {
  value: string;
  label: string;
  id: string;
  placeholder: string;
  disabled?: boolean;
  valid?: boolean;
  noPadding?: boolean;
  noFlex?: boolean;
  validation?: (value: string) => boolean;
  errorMessage?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const TextInput = forwardRef(function TextInput(
  props: TextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [blurred, setBlurred] = React.useState<boolean>(false);
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);

  const handleBlur = () => setBlurred(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    if (props.valid === undefined) {
      setIsValid(props.validation ? props.validation(e.target.value) : true);
    }
    props.onChange(e);
  };

  useEffect(() => {
    if (props.validation || props.valid === undefined) return;
    setIsValid(dirty && props.valid);
  }, [dirty, props.valid, props.validation]);

  const showError =
    blurred && dirty && !isValid && !props.disabled && props.value !== "";

  return (
    <>
      <div
        className={`${props.noPadding ? "" : "mt-4"} ${
          !props.noFlex && "flex flex-row justify-between align-middle"
        } select-none`}
      >
        <label
          className={`px-4 py-1 w-1/3 text-bold text-white ${
            props.disabled && "opacity-30"
          }`}
          htmlFor={props.id}
        >
          {props.label}:
        </label>
        <div className="grow">
          <input
            className={`px-4 py-1 w-full rounded-lg tiny-placeholder ${
              showError && "border border-red-700 bg-red-200"
            }`}
            id={props.id}
            ref={ref}
            value={props.value}
            placeholder={props.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={() => {
              setDirty(false);
              setBlurred(false);
            }}
            disabled={props.disabled}
          />
          {showError && (
            <p className="text-sm italic text-red-400">
              {props.errorMessage ?? "Invalid input"}
            </p>
          )}
        </div>
      </div>
    </>
  );
});

export default TextInput;

import React, { useEffect, useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-hook";
import { useAutocomplete } from "@ubilabs/google-maps-react-hooks";
import {
  browserSupportsWebAuthn,
  startRegistration,
} from "@simplewebauthn/browser";
import env from "../lib/env";
import Button from "./Button";
import TextInput from "./TextInput";
import Checkbox from "./Checkbox";
import { useServiceWorkerContext } from "../hooks/ServiceWorkerContext";
import { UserDoc } from "../lib/backend-types";
import requests from "../lib/requests";
import { Divider } from "./Divider";
import { PushNotificationModal } from "./PushNotificationModal";
import { Footer } from "./Footer";
import { FormText, InfoText } from "./FormTextComponents";
import { AddressForm } from "./AddressForm";
import { VerifyEmailModal } from "./VerifyEmailModal";

type Status = "NONE" | "PENDING" | "SUCCESS" | "FAILURE";

export type FormDataSelection = {
  address: string;
  email: string;
  name: string;
  physicalInvitation: boolean;
  place: any | null;
  updates: boolean;
  pushUpdates: boolean;
};

type RequestState = {
  status: Status;
  error?: string | null;
};

const MIN_NAME_LENGTH = 2;
// emailregex.com
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const webauthnAvailable = browserSupportsWebAuthn();

const ContactForm = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const pushNotiRef = useRef<HTMLDialogElement>(null);
  const [pushOpen, setPushOpen] = React.useState(false);

  const verificationModal = useRef<HTMLDialogElement>(null);
  const [isVerifyOpen, setVerifyOpen] = React.useState(false);

  const [userId, setUserId] = React.useState<string>(
    "23f54bec-11ce-4118-85e0-f26e690c9176"
  );
  const { subscribe, isPushSupported, isIOS } = useServiceWorkerContext();
  const [formData, setFormData] = React.useState<FormDataSelection>({
    address: "",
    email: "",
    name: "",
    physicalInvitation: false,
    updates: false,
    place: null,
    pushUpdates: false,
  });

  const [submissionState, setSubmissionState] = React.useState<RequestState>({
    status: "NONE",
  });
  const [webauthnState, setWebauthnState] = React.useState<RequestState>({
    status: "NONE",
  });
  const [emailVerificationState, setEmailVerificationState] =
    React.useState<RequestState>({
      status: "NONE",
    });
  const [subscriptionState, setSubscriptionState] =
    React.useState<RequestState>({
      status: "NONE",
    });
  const [formHasError, setFormHasError] = React.useState(false);

  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    env.REACT_APP_RECAPTCHA_SITE_KEY,
    { hide: true }
  );

  useEffect(() => {
    const determineState = async () => {
      if (submissionState.status !== "SUCCESS") return;
      if (!formData.updates) return;

      // Check if the user has already verified their email
      if (emailVerificationState.status === "NONE") {
        // const response = { ok: true };
        const response = await fetch(requests.requestVerificationEmail(userId));

        if (response.ok) {
          console.debug("Verification email sent");
          verificationModal.current?.showModal();
          return setVerifyOpen(true);
        } else {
          console.error("Verification email failed to send");
          return setEmailVerificationState({
            status: "FAILURE",
            error: "Verification email failed to send",
          });
        }
      }

      if (!formData.pushUpdates) return;

      if (webauthnAvailable) {
        // Just wait for the user to finish the registration before the noti popup
        if (["NONE", "PENDING"].includes(webauthnState.status)) return;
      }

      // Check if the user has already subscribed to push notifications
      if (isPushSupported) {
        if (subscriptionState.status === "NONE") {
          console.debug("push notifications are supported");
          pushNotiRef.current?.showModal();
          return setPushOpen(true);
        }
      } else {
        console.error("push notifications not supported");
        return setSubscriptionState({
          status: "FAILURE",
          error: "Push notifications are not supported",
        });
      }
    };

    determineState();
  }, [
    submissionState,
    webauthnState,
    emailVerificationState,
    subscriptionState,
    formData.updates,
    formData.pushUpdates,
    isPushSupported,
    userId,
  ]);

  useEffect(() => {
    setFormHasError(
      !(
        formData.name.trim().length > MIN_NAME_LENGTH &&
        emailRegex.test(formData.email.trim()) &&
        (!formData.physicalInvitation ||
          (formData.physicalInvitation && formData.address.trim().length > 0))
      )
    );
  }, [formData]);

  const handleFormChange = (
    changes:
      | Partial<FormDataSelection>
      | ((data: FormDataSelection) => Partial<FormDataSelection>)
  ) =>
    setFormData((formData) => ({
      ...formData,
      ...(changes instanceof Function ? changes(formData) : changes),
    }));

  const onPlaceChanged = (place: any) => {
    if (place) {
      handleFormChange({
        place,
        address: place.formatted_address || place.name,
      });
    }

    inputRef.current && inputRef.current.focus();
  };

  useAutocomplete({
    inputField: inputRef && inputRef.current,
    onPlaceChanged,
  });

  const createAccount = async (userId: string) => {
    if (!userId && submissionState.status === "FAILURE") {
      // TODO: show the user something went wrong
      console.error("Failed data submission");
    }
    setWebauthnState({ status: "PENDING" });

    const registrationResponse = await fetch(
      requests.requestRegistration(userId)
    );
    const registrationBody = await registrationResponse.json();
    const { options } = registrationBody ?? {};
    let attResp;
    try {
      attResp = await startRegistration(options);
    } catch (error) {
      if ((error as any).name === "InvalidStateError") {
        console.warn("Registration already in progress, please wait");
      } else {
        console.error(error);
        setWebauthnState({
          status: "FAILURE",
          error: (error as any).message,
        });
      }

      throw error;
    }

    const verificationResponse = await fetch(
      requests.sendRegistration(userId, attResp)
    );

    const data = await verificationResponse.json();

    if (data && data.verified) {
      console.log("Registration successful");
      setWebauthnState({ status: "SUCCESS" });
    } else {
      setWebauthnState({
        status: "FAILURE",
        error: "Verification failed",
      });
    }
  };

  const generateEmail = (data: FormDataSelection) => {
    const br = "%0D%0A";
    const { name, email, physicalInvitation, address, updates } = data;
    const subject = `Wedding Contact Information - ${name}`;
    const body = `Name: ${name}${br}Email: ${email}${br}Address: ${address}${br}Invitation: ${physicalInvitation}${br}Updates: true${br}${br}${
      physicalInvitation
        ? `Please send a physical invitation to the above address`
        : ""
    }${br}${
      updates ? `Please send updates to my above email address.` : ""
    }${br}${br}Thank you,${br}${name}`;
    return `subject=${subject}&body=${body}`;
  };

  const submit = async () => {
    setSubmissionState({ status: "PENDING" });

    // Validate that the user is not a bot
    const token = await executeGoogleReCaptcha("submit_address_form");
    const response = await fetch(requests.verifyRecaptcha(token));

    if (!response.ok) {
      console.error("Recaptcha verification failed");
      setSubmissionState({
        status: "FAILURE",
        error: "Something went wrong, please try again later",
      });
      return;
    }

    const data = await response.json();

    if (data.body.success) {
      // Submit the form, the recaptcha information, and the webauthn availability
      const response = await fetch(
        requests.submitFormInformation(formData, webauthnAvailable)
      );

      if (!response.ok) {
        setSubmissionState({
          status: "FAILURE",
          error: "Something went wrong, please try again later",
        });
      }

      const userDoc = (await response.json()) as UserDoc;
      const { userId } = userDoc;
      setSubmissionState({ status: "SUCCESS" });
      setUserId(userId);
    } else {
      setSubmissionState({
        status: "FAILURE",
        error: data["error-codes"].join(", "),
      });
    }
  };

  const pushModalHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    console.debug("subscribing to push notifications");
    setSubscriptionState({ status: "PENDING" });
    if (userId) {
      try {
        const permission = await subscribe(userId);
        console.log(permission);
        switch (permission) {
          case "denied":
            throw new Error("permission denied");
          case "granted":
            setSubscriptionState({ status: "SUCCESS" });
            console.log("permission granted");
            break;
          case "prompt":
            console.log("permission prompt");
            break;
        }
      } catch (error) {
        console.error("push notification subscription failed", error);
        setSubscriptionState({
          status: "FAILURE",
          error: (error as any).message,
        });
      }
    } else {
      console.error("no userid found");
    }
    pushNotiRef.current?.close();
    setPushOpen(false);
  };

  const verifyEmailModalHandler = () => {
    verificationModal.current?.close();
    setVerifyOpen(false);
    setEmailVerificationState({ status: "SUCCESS" });
    if (webauthnAvailable) {
      createAccount(userId);
    } else {
      console.debug("webauthn not available");
      setWebauthnState({
        status: "FAILURE",
        error: "Webauthn is not available on this device",
      });
    }
  };

  // Check for final page states
  const getFormView = () => {
    switch (submissionState.status) {
      case "PENDING":
        return (
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-white font-bold text-xl">
              Submitting Your Information!
            </h3>
            <Divider width="w-1/2" />
            <span className="text-white text-center text-sm">
              Please wait while we send your information!
            </span>
            <Divider width="w-1/2" />
          </div>
        );
      case "FAILURE":
        return (
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-white font-bold text-xl">Submission Failed</h3>
            <Divider />
            {submissionState.error && (
              <>
                <span className="text-white text-sm">
                  {submissionState.error}
                </span>
                <Divider />
              </>
            )}
            <span className="block text-white text-base text-center">
              Something must have gone wrong with the code I wrote, and I'm
              sorry! Feel free to send your information instead to my email and
              I will manually add it in. Thank you for your patience!
            </span>
            <Divider />
            <a
              className="mt-8 mb-16 px-10 py-1 border-4 border-wedding-green rounded-xl bg-wedding-blue text-white active:bg-wedding-blue-dark"
              href={`mailto:info-form@anunexpectedwedding.com?${generateEmail(
                formData
              )}`}
            >
              Send Email
            </a>
          </div>
        );
      case "SUCCESS":
        if (subscriptionState.status === "SUCCESS") {
          return (
            <div className="flex flex-col justify-center items-center">
              <h3 className="text-white font-bold text-xl">
                Registered for Updates!
              </h3>
              <Divider width="w-4/5" />
              <span className="text-white text-center text-sm">
                Thank you for registering!
                <span className="block mt-2 text-white text-center text-sm">
                  We'll let you know when all the exciting information is
                  available!
                </span>
              </span>
              <Divider width="w-4/5" />
            </div>
          );
        } else {
          return (
            <div className="flex flex-col justify-center items-center">
              <h3 className="text-white font-bold text-xl">
                Thank you for your information!
              </h3>
              <Divider width="w-1/2" />
              <span className="text-white text-center text-sm">
                We'll be sending you a digital invitation soon!
              </span>
              {formData.updates && (
                <span className="text-white text-center text-sm">
                  A few dialogs will help with the rest of the account creation
                  process.
                </span>
              )}
              <Divider width="w-1/2" />
            </div>
          );
        }
      default:
        return (
          <>
            <form
              id="address-form"
              className="flex flex-col justify-between align-middle"
            >
              <h3 className="mx-auto text-white font-bold text-xl">
                Information Request Form
              </h3>
              <Divider />
              <FormText>
                <span>
                  While the planning of the wedding is still in progress, we'd
                  like to send you more information as it's available. While{" "}
                  <span className="italic">everyone</span> will be sent a
                  digital invitation, you can request a physical one as well!
                </span>
                {isPushSupported && (
                  <span className="block mt-2">
                    You can also request{" "}
                    <span className="font-bold">push notifications</span> for
                    updates as we post more to this site including dates &
                    times, RSVP, engagement photos, wedding photos, and more!
                  </span>
                )}
              </FormText>
              <Divider />
              <TextInput
                id="name-input"
                label="Name"
                onChange={(event) =>
                  handleFormChange({ name: event.target.value })
                }
                placeholder="e.g. Nadine and Brian Christensen"
                errorMessage="Please enter a first and last name"
                validation={(value) =>
                  value.trim().length > MIN_NAME_LENGTH && Boolean(value)
                }
                value={formData.name}
              />
              <TextInput
                id="email-input"
                label="Email"
                onChange={(event) =>
                  handleFormChange({ email: event.target.value })
                }
                placeholder="We'll send digital invitations soon!"
                errorMessage="Please enter a valid email"
                validation={(value) => emailRegex.test(value)}
                value={formData.email}
              />
              <AddressForm
                formData={formData}
                handleFormChange={handleFormChange}
              />
              <Checkbox
                className="ml-4"
                onToggle={() =>
                  handleFormChange((data) => ({
                    updates: !data.updates,
                  }))
                }
                value={formData.updates}
                id="subscribe-to-updates"
                label="I would like updates about the wedding, updates to this site, and a chance to RSVP"
              />
              {formData.updates && (
                <>
                  <InfoText>
                    We will be sending updates via email, so be sure to check!
                  </InfoText>
                  {isPushSupported && (
                    <>
                      <Checkbox
                        className="ml-4"
                        onToggle={() =>
                          handleFormChange((data) => ({
                            pushUpdates: !data.pushUpdates,
                          }))
                        }
                        value={formData.pushUpdates}
                        id="subscribe-to-push"
                        label="I would also like push notifications sent to this device!"
                      />
                      {formData.pushUpdates && (
                        <InfoText>
                          In order to also receive push notification updates to
                          this device, please be sure to select{" "}
                          <b className="text-wedding-gold">"Allow"</b> when the
                          site asks for permission.
                        </InfoText>
                      )}
                    </>
                  )}
                </>
              )}
              {webauthnAvailable &&
              (formData.physicalInvitation || formData.updates) ? (
                <>
                  <Divider width="w-full" color="border-wedding-gold" />
                  <FormText>
                    <span className="mb-4 text-justify items-center block">
                      When you click{" "}
                      <b className="text-wedding-gold">
                        "Submit & Create Account"
                      </b>{" "}
                      below, you will see a dialog asking you to create a
                      "passkey". This is just like a password, but:
                    </span>
                    <Divider width="4/5" />
                    <ul className="list-disc list-inside">
                      <li className="ml-2 md:ml-8">
                        You won't have to type it out
                      </li>
                      <li className="ml-2 md:ml-8">
                        You wont have to remember it
                      </li>
                      <li className="ml-2 md:ml-8">
                        You can use fingerprint or face recognition
                      </li>
                    </ul>
                    <Divider width="4/5" />
                    <span className="mb-4 text-justify items-center block">
                      Simply follow the instructions on the pop-up. Once you've
                      created your account, we can show you more information
                      about the wedding specific to you!
                    </span>
                  </FormText>
                  <Button onClick={submit} disabled={formHasError}>
                    Submit & Create Account
                  </Button>
                </>
              ) : (
                <Button onClick={submit} disabled={formHasError}>
                  Submit
                </Button>
              )}
              <span className="block w-4/5 mx-auto mt-4 text-center text-white text-sm opacity-50">
                * This is a private site by Audrey's brother, not affiliated
                with any company.
                <span className="block italic">
                  Your information is private and safe, and we won't bug you
                  with unnecessary information
                </span>
              </span>
            </form>
            <div className="my-4 mb-24 mx-auto w-3/4 border-b-2 border-dotted border-gray-300" />
          </>
        );
    }
  };

  // Send default page and submission form
  return (
    <>
      {getFormView()}
      <Footer />
      <VerifyEmailModal
        ref={verificationModal}
        isOpen={isVerifyOpen}
        onClick={verifyEmailModalHandler}
        userId={userId}
      />
      <PushNotificationModal
        ref={pushNotiRef}
        isOpen={pushOpen}
        onClick={pushModalHandler}
        isIOS={isIOS}
      />
    </>
  );
};

export { ContactForm };

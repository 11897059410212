import React from "react";

export const Divider = ({
  width = "w-3/4",
  dotted = true,
  dashed = false,
  color = "border-white",
}) => (
  <div
    className={`my-4 mx-auto ${width} border-b-2 ${dotted && "border-dotted"} ${
      dashed && "border-dashed"
    } border-gray-300 ${color}`}
  />
);

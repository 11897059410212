import { ContactForm } from "../components/ContactForm";
import React from "react";
import { FilledQuotationMark } from "../icons";

function SaveTheDate() {
  const [doofHover, setDoofHover] = React.useState(false);
  const [touch, setTouch] = React.useState(false);
  const [showAltText, setShowAltText] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(
      () => setShowAltText(doofHover),
      doofHover ? 1000 : 100
    );

    if (touch) {
      const touchTimeout = setTimeout(() => {
        setTouch(false);
        setDoofHover(false);
      }, 4000);

      return () => {
        clearTimeout(touchTimeout);
        clearTimeout(timeout);
      };
    }

    return () => clearTimeout(timeout);
  }, [doofHover, touch]);

  return (
    <div id="save-the-date" className="select-text">
      <div className="m-auto my-8 md:max-w-4xl lg:max-w-6xl">
        <div className="mx-2 px-4 lg:px-0 mb-2 w-full flex flex-row justify-between align-middle select-none">
          <img
            alt="logo"
            src="static/logo.png"
            className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24"
          />
          <span className="inline-block pt-4 sm:pt-4 md:pt-6 lg:pt-5 text-center text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-hobbiton font-bold text-gray-100">
            An Unexpected Wedding
          </span>
          <img
            alt="logo"
            src="static/logo.png"
            className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24"
          />
        </div>
        <div
          className="mx-auto mb-8 w-full select-none text-center font-beloved text-sm text-white opacity-80"
          onMouseOver={() => setDoofHover(true)}
          onMouseLeave={() => setDoofHover(false)}
          onTouchStart={() => {
            setDoofHover(true);
            setTouch(true);
          }}
        >
          <div className="h-5">
            <span
              className={`inline-block ${showAltText && "opacity-0 absolute"}`}
            >
              Well, not entirely <b>UN</b>expected
            </span>
            <span
              className={`inline-block ${!showAltText && "opacity-0 absolute"}`}
            >
              And by unexpected, I mean{" "}
              <span className="font-boucherie font-bold">
                COMPLETELY EXPECTED!!
              </span>
            </span>
          </div>
          <span className="inline-block ml-2">...</span>
          <span className="inline-block ml-2">
            just like, faster than expected, that's all
          </span>
        </div>
        <div className="mx-auto mb-8 w-3/4 md:w-4/5 relative">
          <span className="text-sm md:text-2xl select-none font-beloved text-justify font-bold text-gray-100">
            It's a dangerous business, Frodo, going out your door. You step onto
            the road, and if you don't keep your feet, there's no knowing where
            you might be swept off to.
          </span>
          <div className="absolute -top-2 -left-10 md:-left-12">
            <FilledQuotationMark className="w-8 md:w-10 fill-gray-100 rotate-180" />
          </div>
          <div className="absolute -bottom-4 -right-6">
            <FilledQuotationMark className="w-8 md:w-10 fill-gray-100" />
          </div>
        </div>
        <img
          className="px-4 max-w-full"
          src="/static/SavetheDateRingSm.png"
          alt="Pictures of Audrey and Joel, with the text 'Save the date! Saturday, November 18 Bountiful, Utah Temple - Invitations to follow'"
        />
        <div className="mt-8 flex flex-row justify-center">
          <img alt="logo" src="static/logo.png" className="w-32 h-32" />
        </div>
        <div className="mt-8 mx-4 flex justify-center align-middle">
          <div id="request" className="w-11/12 sm:w-4/5 md:w-3/5 lg:w-3/5">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveTheDate;

import React, { useEffect, useRef } from "react";
import { Divider } from "./Divider";
import { AppleShare, ArrowDoubleDown } from "../icons";
import Button from "./Button";

const showiosspecifics = false;
interface PushNotificationModalProps {
  isOpen: boolean;
  onClick: React.FormEventHandler<HTMLFormElement>;
  isIOS: boolean;
}

export const PushNotificationModal = React.forwardRef<
  HTMLDialogElement,
  PushNotificationModalProps
>(function PushNotificationModal(props, ref) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [reachedBottom, setReachedBottom] = React.useState(false);
  const [overflow, setOverflow] = React.useState(false);

  useEffect(() => {
    if (!formRef.current) return;
    const form = formRef.current;
    const handleClick = (event: MouseEvent) => {
      if (!event.target) return;
      if (!form.contains(event.target as Node) && event.target !== form) {
        form.submit();
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    if (!scrollRef.current || !props.isOpen) return;
    const evaluate = () => {
      if (!scrollRef.current) return;
      const div = scrollRef.current;
      const isOver =
        div.offsetHeight < div.scrollHeight ||
        div.offsetWidth < div.scrollWidth;
      setOverflow(isOver);

      if (isOver) {
        const scrollListener = () => {
          const bottom = div.scrollHeight - div.scrollTop === div.clientHeight;
          setReachedBottom(bottom);
        };
        div.addEventListener("scroll", scrollListener);
        return () => div.removeEventListener("scroll", scrollListener);
      }
    };
    const interval = setInterval(evaluate, 100);
    return () => clearInterval(interval);
  }, [props.isOpen]);

  const handleScrollDown = () =>
    scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight);

  return (
    <dialog
      id="allow-notifications-dialog"
      ref={ref}
      className="group w-4/5 h-4/5 lg:w-2/3 lg:h-3/4 xl:w-1/2 2xl:w-2/5 max-h-xl open:animate-dialog-open border-2 rounded-3xl drop-shadow-lg backdrop:opacity-20 backdrop:bg-gray-200 border-gray-400 bg-wedding-blue-dark"
    >
      <form
        id="allow-notifications-form"
        ref={formRef}
        method="dialog"
        className="p-8 h-full w-full group-open:flex group-open:flex-col justify-between align-middle"
      >
        <div className="relative group-open:flex group-open:flex-col justify-between h-1/2 grow">
          <h1 className="mb-4 text-center font-beloved font-bold text-gray-200 text-2xl">
            Allow Notifications
          </h1>
          <div
            ref={scrollRef}
            className="px-4 text-white text-xs md:text-sm overflow-x-auto grow"
          >
            {!showiosspecifics && !props.isIOS && (
              <span className="mb-4 text-justify items-center block">
                When you click "Request Permission" below, you will see a pop-up
                asking you to allow notifications from this site.
              </span>
            )}
            <Divider width="w-full" />
            {props.isIOS && showiosspecifics ? (
              <>
                <span className="mb-4 text-justify items-center block">
                  For iOS devices, I'm blocked from sending web push
                  notifications without you taking some special steps. If you
                  would like that, then I will provide the steps below, if not,
                  then simply press outside this dialog box to close it and
                  you're good to go! Your information has been submitted and
                  we'll send you notification via email.
                </span>
                <Divider width="w-full" />
                <ul className="mx-auto w-4/5 list-disc list-outside">
                  <li className="flex flex-row justify-center align-middle">
                    <span className="mb-4 text-justify items-center block">
                      To allow notifications on iOS{" "}
                      <span className="text-gray-300">
                        (compatible with iOS 16.4+)
                      </span>
                      , you'll need to press the "share" icon in the top-left
                      corner that looks like this:
                    </span>
                    <span className="ml-8">
                      <AppleShare className="w-12 h-12 p-1 fill-white bg-black rounded-lg shrink" />
                    </span>
                  </li>
                  <li className="text-justify items-center block">
                    Then scroll down and select "Add to Home Screen{" "}
                    {String.fromCharCode(91)}+{String.fromCharCode(93)}",
                  </li>
                  <li className="mx-8 mb-4 text-gray-300 text-justify items-center block">
                    You'll see the icon as a heart, and the title and url of the
                    site.
                  </li>
                  <li className="mb-4 text-justify items-center block">
                    Then select "Add" in the top-right corner and you'll see it
                    added on your homescreen.
                  </li>
                  <li className="mb-4 text-justify items-center block">
                    Open the app via that icon, and you'll be able to allow push
                    notifications through this same dialog box.
                  </li>
                  <li className="mb-4 text-justify items-center block">
                    Good luck!
                  </li>
                </ul>
              </>
            ) : (
              <span className="mb-4 text-justify items-center block">
                Simply click "Allow" and then we'll be able to send
                notifications directly to your device! If not, then we'll simply
                send them to the email you provided.
              </span>
            )}
          </div>
          {overflow && !reachedBottom && (
            <div
              className="flex justify-center animate-bounce align-middle absolute hover:cursor-pointer -bottom-4 m-auto w-full bg-white bg-opacity-5"
              onClick={handleScrollDown}
            >
              <ArrowDoubleDown className="w-4 h-4 fill-white" />
            </div>
          )}
        </div>
        <Button type="submit" onClick={props.onClick} autoFocus>
          Request Permission
        </Button>
      </form>
    </dialog>
  );
});

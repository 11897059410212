import React, { useEffect, useRef } from "react";
import { Divider } from "./Divider";
import Button from "./Button";
import { ModalText } from "./ModalComponents";
import TextInput from "./TextInput";
import {
  requestVerificationEmail,
  sendVerificationCode,
} from "../lib/requests";
import { Spinner } from "./Spinner";

interface VerifyEmailModalProps {
  isOpen: boolean;
  onClick: React.FormEventHandler<HTMLFormElement>;
  userId: string;
}

type ErrorMessage =
  | "Too many attempts"
  | "Invalid code"
  | "Bad request"
  | "User locked";

export const VerifyEmailModal = React.forwardRef<
  HTMLDialogElement,
  VerifyEmailModalProps
>(function VerifyEmailModal(props, ref) {
  const { isOpen, onClick, userId } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ErrorMessage | null>(null);

  useEffect(() => {
    if (emailVerified && isOpen) {
      const timeout = setTimeout(() => {
        onClick && onClick(null as any);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [emailVerified, isOpen, onClick]);

  const handleVerification = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    const response = await fetch(sendVerificationCode(userId, code));
    setLoading(false);

    if (response.ok) {
      setEmailVerified(true);
    } else {
      switch (response.status) {
        case 400:
          setError("Bad request");
          break;
        case 401:
          setError("Invalid code");
          break;
        case 403:
          setError("User locked");
          break;
        case 429:
          setError("Too many attempts");
          break;
      }
      setEmailVerified(false);
    }
  };

  const resend = async () => fetch(requestVerificationEmail(userId));

  return (
    <dialog
      id="verify-email-dialog"
      ref={ref}
      className="group w-4/5 lg:w-2/3 xl:w-1/2 2xl:w-2/5 max-w-lg open:animate-dialog-open border-2 rounded-3xl drop-shadow-lg backdrop:opacity-20 backdrop:bg-gray-200 border-gray-400 bg-wedding-blue-dark overflow-auto"
    >
      <form
        id="verify-email-form"
        ref={formRef}
        method="dialog"
        className="p-8 min-h-[24rem] w-full group-open:flex group-open:flex-col justify-between align-middle"
      >
        <div className="group-open:flex group-open:flex-col justify-between min-h-[16rem] grow">
          <h1 className="mb-4 text-center font-beloved font-bold text-gray-200 text-2xl">
            Verify Email
          </h1>
          <div ref={scrollRef} className="px-4 ">
            <ModalText>
              I've sent a verification code to your email address. Please check
              your email, and you can copy that code into the box below. Then
              we'll be all good to go to create the account!
            </ModalText>
            <ModalText noPadding={true} emphasis>
              Be sure to check your spam folder as well!
            </ModalText>
          </div>
          <Divider width="w-4/5" />
          <div className="mx-auto w-52 sm:w-72">
            {emailVerified ? (
              <ModalText centered={true} noPadding={true}>
                Verification successful!
              </ModalText>
            ) : (
              <TextInput
                id="verify-email-code-input"
                label="Code"
                onChange={(event: any) => {
                  setError(null);
                  setCode(event.target.value);
                }}
                errorMessage={error as string}
                placeholder="Verification code"
                value={code}
                noPadding={true}
                disabled={loading}
              />
            )}
          </div>
          <Divider width="w-4/5" />
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Button type="submit" onClick={handleVerification} autoFocus>
            Submit Code
          </Button>
        )}
        <div
          className="mt-4 mx-auto text-center bg-wedding-blue px-2 py-1 rounded-lg border border-white hover:cursor-pointer active:bg-wedding-blue-dark"
          onClick={resend}
        >
          <ModalText centered={true} noPadding={true}>
            Resend email
          </ModalText>
        </div>
      </form>
    </dialog>
  );
});

import React, { useEffect } from "react";
import { Link } from "../icons";

const Banner = () => {
  return (
    <a
      className="fixed top-0 block animate-slide-down w-full py-2 text-lg text-center font-beloved font-bold bg-wedding-blue-dark text-wedding-gold"
      href="/rsvp"
    >
      Still need to RSVP? Click here!
    </a>
  );
};
export const Home = () => {
  const [showPrompt, setShowPrompt] = React.useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowPrompt(true), 6000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (showPrompt) {
      const timeout = setTimeout(() => setShowPrompt(false), 10000);
      return () => clearTimeout(timeout);
    }
  }, [showPrompt]);

  return (
    <>
      {showPrompt && <Banner />}
      <section
        className={`w-screen h-screen xl:pt-8 bg-hero-vertical md:bg-hero bg-cover bg-no-repeat bg-[position:_60%_center] md:bg-center min-h-[48rem] lg:min-h-[58rem]`}
        style={{
          ...(showPrompt && { paddingTop: "4rem" }),
        }}
      >
        <div className="md:w-3/5 lg:w-1/2 md:pl-8">
          <h1 className="text-xl xs:text-2xl lg:text-3xl font-beloved font-bold text-center text-white">
            <span className="inline-block">You are invited to celebrate</span>{" "}
            <span className="inline-block">the wedding of</span>
          </h1>

          <div className="mt-10 sm:mt-16 max-w-[36rem] mx-auto text-5xl sm:text-6xl lg:text-6xl xl:text-8xl text-center font-licorice text-white">
            <div>Audrey Christensen</div>
            <div>&</div>
            <div> Joel Meservy</div>
          </div>

          <div className="mt-8 xs:mt-16 text-base xs:text-xl font-beloved text-center text-white">
            <span className="inline-block">
              Join us for light refreshments,
            </span>{" "}
            <span className="inline-block">music and fun!</span>
          </div>

          <div className="footer max-w-4xl mx-auto pb-8 pt-4 sm:mt-64 md:mt-12 text-base xs:text-xl font-beloved text-center md:rounded-xl md:py-4 md:px-2 md:bg-opacity-40 bg-opacity-50 text-white bg-black">
            <a
              className="flex items-center justify-center align-middle"
              target="_blank"
              rel="noreferrer"
              href="https://maps.app.goo.gl/PKNJ4C1g6pWEd9597"
            >
              720 E 550 N, Bountiful, UT{" "}
              <Link className="ml-2 w-5 h-5 inline-block fill-white" />
            </a>
            <span className="block">November 18, 2023</span>
            <div className="grid grid-cols-2 gap-2 m-auto 2xl:w-8/12 3xl:w-5/12">
              <span>3:30-4:00 PM</span>
              <span>Ring Ceremony</span>
              <span>4:00-5:30 PM</span>
              <span>Reception</span>
            </div>
            <span className="block my-2 border-b border-dotted w-1/2 mx-auto" />
            <span className="block mt-4 md:mt-8 text-xs md:text-base lg:text-lg">
              <span className="inline-block">
                Audrey and Joel are registered at
              </span>{" "}
              <span className="inline-block mt-2 lg:mt-0">
                <a
                  className="px-2 bg-opacity-40 rounded-md font-bold bg-gray-400"
                  href="https://www.amazon.com/wedding/registry/2IIA8GALQ89Y2"
                  target="_blank"
                  rel="noreferrer"
                >
                  Amazon <Link className="w-3 h-3 inline-block fill-white" />
                </a>{" "}
                and{" "}
                <a
                  className="px-2 bg-opacity-40 rounded-md font-bold bg-gray-400"
                  href="https://www.target.com/gift-registry/gift-giver?registryId=8e5c0380-7202-11ee-9674-1f901aea94dd&type=WEDDING"
                  target="_blank"
                  rel="noreferrer"
                >
                  Target <Link className="w-3 h-3 inline-block fill-white" />
                </a>
              </span>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

import React from "react";
export const Footer = () => (
  <>
    <div className="mt-8 text-xs text-center text-white opacity-40 select-none">
      This site is protected by reCAPTCHA and the Google{" "}
      <a className="font-bold" href="https://policies.google.com/privacy">
        Privacy Policy
      </a>{" "}
      and{" "}
      <a className="font-bold" href="https://policies.google.com/terms">
        Terms of Service
      </a>{" "}
      apply.
    </div>
    <div className="text-xs text-center text-white opacity-40 select-none">
      © 2023{" "}
      <a
        href="https://github.com/devtanc"
        target="_blank"
        rel="noopener noreferrer"
      >
        devtanc
      </a>
    </div>
    <div className="block h-32" />
  </>
);

import React, { useContext, useEffect } from "react";
import env from "../lib/env";
import requests from "../lib/requests";

const pushOptions = {
  userVisibleOnly: true,
  applicationServerKey: env.REACT_APP_PUBLIC_VAPID_KEY,
};

type Context = {
  subscribe: (userId: string) => Promise<PermissionState>;
  unsubscribe: (userId: string) => Promise<void>;
  isPushSupported: boolean;
  isIOS: boolean;
};

export const register = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        `${process.env.PUBLIC_URL}/sw.js`,
        { scope: `/` }
      );
      console.debug("Service worker registration succeeded:", registration);
    } catch (error) {
      console.error("Service worker registration failed:", error);
    }
  } else {
    console.error("Service workers are not supported.");
  }
};

register();

export const ServiceWorkerContext = React.createContext<Context>({
  subscribe: async () => "denied",
  unsubscribe: async () => {},
  isPushSupported: false,
  isIOS: false,
});

export const ServiceWorkerWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSupported, setIsSupported] = React.useState(false);
  const [isIOS, setIsIOS] = React.useState(false);

  useEffect(() => {
    const checkSupport = async () => {
      console.debug("checking for push support");
      if (!navigator.serviceWorker) return setIsSupported(false);
      const registration = await navigator.serviceWorker.ready;
      if (
        !registration.pushManager ||
        !registration.pushManager.permissionState
      )
        return setIsSupported(false);
      let permission = await registration.pushManager.permissionState(
        pushOptions
      );
      if (permission === "denied") return setIsSupported(false);
      return setIsSupported(true);
    };

    const checkIOS = () => {
      const result =
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document);
      setIsIOS(result);
    };

    checkSupport();
    checkIOS();
  }, []);

  const subscribe = async (userId: string): Promise<PermissionState> => {
    if (!navigator.serviceWorker) return "denied";
    console.debug("serviceworker found");
    const registration = await navigator.serviceWorker.ready;
    console.debug("registration found", registration);
    if (!registration.pushManager || !registration.pushManager.permissionState)
      return "denied";
    console.debug("pushManager found");
    let permission = await registration.pushManager.permissionState(
      pushOptions
    );
    console.debug("permission found", permission);
    if (permission === "denied") return permission;
    if (permission === "prompt") {
      permission = (await Notification.requestPermission()) as PermissionState;
      if (permission === "denied") return permission;
    }

    try {
      const subscription = await registration.pushManager.subscribe(
        pushOptions
      );
      console.debug("subscription successful", subscription);

      const response = await fetch(requests.subscribe(userId, subscription));

      if (!response.ok) {
        console.error(
          `backend subscription request failed: [${response.status}: ${
            response.statusText
          }] - ${await response.text()}`
        );
      }

      return permission;
    } catch (error) {
      console.error("browser subscription failed", error);
      return "denied";
    }
  };

  const unsubscribe = async (userId: string) => {
    const registration = await navigator.serviceWorker.ready;
    const current = await registration.pushManager.getSubscription();
    if (!current) return;
    try {
      await current.unsubscribe();
      const response = await fetch(requests.unsubscribe(userId));

      if (!response.ok) {
        console.error(
          `backend unsubscription request failed: [${response.status}: ${
            response.statusText
          }] - ${await response.text()}`
        );
      }
      console.debug("browser unsubscription successful");
    } catch (error) {
      console.error("browser unsubscription failed", error);
    }
  };

  return (
    <ServiceWorkerContext.Provider
      value={{ subscribe, unsubscribe, isPushSupported: isSupported, isIOS }}
    >
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export const useServiceWorkerContext = () => useContext(ServiceWorkerContext);

import React from "react";
const Button = ({ children, ...props }: React.PropsWithChildren & any) => (
  <a
    href="#request"
    className={`mt-8 mx-auto px-8 py-2 rounded-lg max-w-fit text-sm md:text-base select-none text-slate-100 bg-wedding-purple border-slate-300 border-2 hover:cursor-pointer active:bg-purple-300 ${
      props.disabled && "opacity-40"
    }`}
    {...props}
  >
    {children}
  </a>
);

export default Button;

import { FormDataSelection } from "../components/ContactForm";
import env from "./env";

export const requestRegistration = (userId: string) =>
  new Request(`${env.BACKEND_URL}/rest/auth/registration?userId=${userId}`, {
    credentials: "include",
  });

export const sendRegistration = (userId: string, attResp: any) =>
  new Request(`${env.BACKEND_URL}/rest/auth/registration`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...attResp, userId }),
    credentials: "include",
  });

export const verifyRecaptcha = (token: string) =>
  new Request(`${env.BACKEND_URL}/rest/recaptcha/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
    credentials: "include",
  });

export const submitFormInformation = (
  formData: FormDataSelection,
  webauthnAvailable: boolean
) =>
  new Request(`${env.BACKEND_URL}/rest/address/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      formData,
      webauthnAvailable,
    }),
    credentials: "include",
  });

export const unsubscribe = (userId: string) =>
  new Request(`${env.BACKEND_URL}/rest/push/subscription?userId=${userId}`, {
    method: "DELETE",
    credentials: "include",
  });

export const subscribe = (userId: string, subscription: any) =>
  new Request(`${env.BACKEND_URL}/rest/push/subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, subscription }),
    credentials: "include",
  });

export const requestVerificationEmail = (userId: string) =>
  new Request(`${env.BACKEND_URL}/rest/auth/send-verification-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId }),
    credentials: "include",
  });

export const sendVerificationCode = (
  userId: string,
  verificationCode: string
) =>
  new Request(`${env.BACKEND_URL}/rest/auth/email-code-verification`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, verificationCode }),
    credentials: "include",
  });

const requests = {
  requestRegistration,
  requestVerificationEmail,
  sendRegistration,
  submitFormInformation,
  subscribe,
  unsubscribe,
  verifyRecaptcha,
};

export default requests;

import React, { useRef } from "react";
import { useAutocomplete } from "@ubilabs/google-maps-react-hooks";
import TextInput from "./TextInput";
import Checkbox from "./Checkbox";
import { FormDataSelection } from "./ContactForm";

interface AddressFormProps {
  handleFormChange: (formData: Partial<FormDataSelection>) => void;
  formData: FormDataSelection;
}

const AddressForm = ({ handleFormChange, formData }: AddressFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onPlaceChanged = (place: any) => {
    if (place) {
      handleFormChange({
        place,
        address: place.formatted_address || place.name,
      });
    }

    inputRef.current && inputRef.current.focus();
  };

  useAutocomplete({
    inputField: inputRef && inputRef.current,
    onPlaceChanged,
  });

  // Send default page and submission form
  return (
    <>
      <Checkbox
        className="ml-4"
        onToggle={(checked) =>
          handleFormChange({
            physicalInvitation: checked,
            ...(!checked && {
              address: "",
              place: null,
            }),
          })
        }
        value={formData.physicalInvitation}
        id="physical-invitation"
        label="I would like a physical invitation in addition to the digital one"
      />
      <TextInput
        id="address-input"
        label="Address"
        onChange={(event) =>
          handleFormChange({
            address: event.target.value,
            ...(!event.target.value && { place: null }),
          })
        }
        placeholder="Where should we send your invitation?"
        ref={inputRef}
        value={formData.address}
        errorMessage="If possible, please select your address from the drop-down. If not, then simply continue, we trust you :)"
        valid={Boolean(
          formData.place &&
            (formData.place.formatted_address === formData.address ||
              formData.place.name === formData.address)
        )}
        disabled={!formData.physicalInvitation}
      />
    </>
  );
};

export { AddressForm };

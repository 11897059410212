import React from "react";
import { CircleCheck, CircleEmpty } from "../icons";

interface CheckboxProps {
  value: boolean;
  id: string;
  label: string;
  name?: string;
  description?: string;
  children?: React.ReactNode;
  onToggle: (checked: boolean) => void;
  className?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    props.onToggle(!props.value);
  };

  return (
    <div
      className={`mt-4 flex items-center hover:cursor-pointer ${props.className}`}
      onClick={handleClick}
    >
      <input
        id={props.id}
        type="checkbox"
        value={props.value ? "on" : ""}
        checked={props.value}
        name={props.name ?? props.id}
        hidden
        readOnly
      />
      {props.value ? (
        <CircleCheck className="w-8 h-8 fill-gray-100" />
      ) : (
        <CircleEmpty className="w-8 h-8 fill-gray-100" />
      )}
      <label
        htmlFor={props.id}
        className="w-full ml-2 text-sm font-medium text-gray-100 pointer-events-none select-none"
      >
        {props.children ?? props.label}
        {props.description && (
          <div className="ml-4 opacity-60 text-xs">{`- ${props.description}`}</div>
        )}
      </label>
    </div>
  );
};

export default Checkbox;

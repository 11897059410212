import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";
import React, { StrictMode } from "react";
import env from "./lib/env";
import SaveTheDate from "./pages/SaveTheDate";
import { ServiceWorkerWrapper } from "./hooks/ServiceWorkerContext";
import { Route } from "wouter";
import { Home } from "./pages/Home";

function App() {
  return (
    <div id="app" className="select-text">
      <GoogleMapsProvider
        googleMapsAPIKey={env.REACT_APP_GOOGLE_MAPS_KEY}
        libraries={["places"]}
      >
        <StrictMode>
          <ServiceWorkerWrapper>
            <Route path="/">
              <Home />
            </Route>
            <Route path="/rsvp">
              <SaveTheDate />
            </Route>
          </ServiceWorkerWrapper>
        </StrictMode>
      </GoogleMapsProvider>
    </div>
  );
}

export default App;

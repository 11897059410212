import React from "react";

export const ModalText = ({
  noPadding,
  emphasis,
  paddingTop,
  onClick,
  centered,
  ...props
}: any) => (
  <span
    {...props}
    onClick={onClick}
    className={`${!noPadding && (paddingTop ? "mt-4" : "mb-4")} ${
      centered ? "text-center" : "text-justify"
    } text-xs md:text-sm items-center block ${
      emphasis ? "text-wedding-gold" : "text-white"
    } ${onClick && "cursor-pointer"}`}
  />
);

export const ModalListItem = ({ noPadding, ...props }: any) => (
  <li
    {...props}
    className={`${noPadding ? "" : "mb-4"} text-justify items-center block`}
  />
);

import React from "react";

export const CircleEmpty = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 1.25A10.75 10.75 0 1 0 22.75 12 10.762 10.762 0 0 0 12 1.25Zm0 20A9.25 9.25 0 1 1 21.25 12 9.26 9.26 0 0 1 12 21.25Z" />
  </svg>
);

export const CircleCheck = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 1.25A10.75 10.75 0 1 0 22.75 12 10.762 10.762 0 0 0 12 1.25Zm0 20A9.25 9.25 0 1 1 21.25 12 9.26 9.26 0 0 1 12 21.25Zm4.03-12.114a.75.75 0 0 1 0 1.061l-4.666 4.667a.752.752 0 0 1-1.061 0L7.97 12.53a.75.75 0 0 1 1.06-1.06l1.8 1.8 4.14-4.134a.751.751 0 0 1 1.06 0Z" />
  </svg>
);

export const QuotationMark = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      data-name="quote"
      d="M19.5 4.5h-5c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h1.691l-2.276 4.553c-.156.312-.14.676.044.973s.501.474.851.474h2.573c.572 0 1.086-.318 1.342-.829l2.565-5.131c.138-.276.211-.585.211-.894V6.5c0-1.103-.897-2-2-2Zm1 8.146c0 .154-.037.309-.105.447l-2.565 5.131a.498.498 0 0 1-.447.276H14.81l2.638-5.276a.5.5 0 0 0-.447-.724h-2.5c-.551 0-1-.448-1-1v-5c0-.552.449-1 1-1h5c.551 0 1 .448 1 1v6.146ZM9.5 4.5h-5c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h1.691l-2.276 4.553c-.156.312-.14.676.044.973s.501.474.851.474h2.573c.572 0 1.086-.318 1.342-.829l2.565-5.131c.138-.276.211-.585.211-.894V6.5c0-1.103-.897-2-2-2Zm1 8.146c0 .154-.037.309-.105.447L7.83 18.224a.498.498 0 0 1-.447.276H4.81l2.638-5.276a.5.5 0 0 0-.447-.724h-2.5c-.551 0-1-.448-1-1v-5c0-.552.449-1 1-1h5c.551 0 1 .448 1 1v6.146Z"
    />
  </svg>
);

export const FilledQuotationMark = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="m20.842 13.317-2.565 5.13a1 1 0 0 1-.894.553H14.81a.5.5 0 0 1-.447-.724L17.001 13h-2.5a1.5 1.5 0 0 1-1.5-1.5v-5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v6.146c0 .233-.054.463-.158.671ZM11 12.646V6.5A1.5 1.5 0 0 0 9.5 5h-5A1.5 1.5 0 0 0 3 6.5v5A1.5 1.5 0 0 0 4.5 13H7l-2.638 5.276a.5.5 0 0 0 .447.724h2.573a.998.998 0 0 0 .894-.553l2.565-5.13c.104-.208.158-.438.158-.671Z" />
  </svg>
);

export const ArrowDoubleDown = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.53 11.47a.749.749 0 0 1 0 1.06l-7 7a.748.748 0 0 1-1.06 0l-7-7a.75.75 0 0 1 1.06-1.06L12 17.939l6.47-6.469a.749.749 0 0 1 1.06 0Zm-8.06 1.06a.748.748 0 0 0 1.06 0l7-7a.75.75 0 0 0-1.06-1.06L12 10.939 5.53 4.47a.75.75 0 0 0-1.06 1.06Z" />
  </svg>
);

export const AppleShare = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <path d="M30.3 13.7 25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" />
    <path d="M24 7h2v21h-2z" />
    <path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
  </svg>
);

export const Link = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M10.88 13.87a.744.744 0 0 1-.53-.22 4.663 4.663 0 0 1-.474-6.094 3.632 3.632 0 0 1 .473-.566l3.36-3.36a4.709 4.709 0 1 1 6.659 6.66l-1.84 1.84a.75.75 0 1 1-1.06-1.06l1.84-1.84a3.215 3.215 0 0 0 0-4.54 3.285 3.285 0 0 0-4.538 0l-3.36 3.36a2.44 2.44 0 0 0-.3.358 3.189 3.189 0 0 0 .3 4.182.75.75 0 0 1-.53 1.28Zm-3.919 7.88a4.71 4.71 0 0 1-3.329-8.04l1.84-1.84a.75.75 0 1 1 1.06 1.06l-1.84 1.84a3.215 3.215 0 0 0 0 4.54 3.285 3.285 0 0 0 4.538 0l3.36-3.36a3.092 3.092 0 0 0 .749-1.188 3.174 3.174 0 0 0-.749-3.352.75.75 0 1 1 1.06-1.06 4.713 4.713 0 0 1 1.106 4.9 4.57 4.57 0 0 1-1.106 1.76l-3.359 3.36a4.678 4.678 0 0 1-3.33 1.38Z" />
  </svg>
);

import React from "react";

export const InfoText = (props: any) => (
  <span
    {...props}
    className="ml-16 mt-4 px-4 text-sm text-justify border-l-4 border-wedding-gold text-white"
  />
);

export const FormText = (props: any) => (
  <div
    {...props}
    className={`px-4 text-justify text-white text-sm ${props.className}`}
  />
);

/* eslint-disable no-restricted-globals */
const pointLocal = process.env.REACT_APP_POINT_LOCAL === "1";
console.log("point local:", pointLocal);
const env = {
  REACT_APP_RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? "",
  REACT_APP_GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? "",
  BACKEND_URL: pointLocal
    ? "http://localhost:4000"
    : "https://backend.anunexpectedwedding.com",
  IS_LOCAL:
    location.hostname === "localhost" || location.hostname === "127.0.0.1",
  REACT_APP_PUBLIC_VAPID_KEY: process.env.REACT_APP_PUBLIC_VAPID_KEY ?? "",
};

export default env;
